<template>
    <div class="page-body">
        <div class="page-header">
            <div class="crumbs">
                <el-breadcrumb separator="/">
                    <el-breadcrumb-item>
                        <i class="el-icon-s-management">信息中心</i>
                    </el-breadcrumb-item>
                </el-breadcrumb>
            </div>
        </div>
        <div class="table-body">
            <div class="container">
                <el-tabs type="border-card" v-model="message">
                    <el-tab-pane name="first" :label="`未读消息(${unread.length})`">
                        <el-table :data="unread" :show-header="false" style="width: 100%">
                            <el-table-column>
                                <template slot-scope="scope">
                                    <el-button type="text" size="mini" style="font-size: 14px" class="message-title"
                                               @click="showContent(scope.row)">{{scope.row.title}}
                                    </el-button>
                                </template>
                            </el-table-column>
                            <el-table-column prop="date" width="180"></el-table-column>
                            <el-table-column width="120">
                                <template slot-scope="scope">
                                    <el-button size="small" @click="handleRead(scope.row.id)">标为已读</el-button>
                                </template>
                            </el-table-column>
                        </el-table>
                    </el-tab-pane>

                    <el-tab-pane name="second" :label="`已读消息(${read.length})`">
                        <el-table :data="read" :show-header="false" style="width: 100%">
                            <el-table-column>
                                <template slot-scope="scope">
                                    <el-button type="text" size="small" style="font-size: 14px" class="message-title"
                                               @click="showContent(scope.row)">{{scope.row.title}}
                                    </el-button>
                                </template>
                            </el-table-column>
                            <el-table-column prop="date" width="180"></el-table-column>
                            <el-table-column width="120">
                                <template slot-scope="scope">
                                    <el-button plain size="small" @click="handleRecycle(scope.row.id)">添加到回收站
                                    </el-button>
                                </template>
                            </el-table-column>
                            <el-table-column width="120">
                                <template slot-scope="scope">
                                    <el-button plain type="danger" size="small" @click="deleteMessage(scope.row.id)">
                                        删除消息
                                    </el-button>
                                </template>
                            </el-table-column>
                        </el-table>
                    </el-tab-pane>

                    <el-tab-pane name="third" :label="`回收站(${recycle.length})`">
                        <el-table :data="recycle" :show-header="false" style="width: 100%">
                            <el-table-column>
                                <template slot-scope="scope">
                                    <el-button type="text" size="small" style="font-size: 14px" class="message-title"
                                               @click="showContent(scope.row)">{{scope.row.title}}
                                    </el-button>
                                </template>
                            </el-table-column>
                            <el-table-column prop="date" width="180"></el-table-column>
                            <el-table-column width="120">
                                <template slot-scope="scope">
                                    <el-button plain type="danger" size="small" @click="deleteMessage(scope.row.id)">
                                        删除消息
                                    </el-button>
                                </template>
                            </el-table-column>
                        </el-table>
                    </el-tab-pane>
                </el-tabs>
            </div>
        </div>
        <div class="page-footer">
            <Pagination :pageSize="option.pageSize" :pageTotal="totalPage" @handlePageChange="pageChange"/>
        </div>
        <div class="dialog">
            <el-dialog
                    :title="title"
                    :visible.sync="dialogVisible"
                    width="30%"
                    :close-on-click-modal="false"
                    center>
                <span>{{content}}</span>
                <span slot="footer" class="dialog-footer">
                    <el-button type="primary" @click="handleRead(id)">确 定</el-button>
                </span>
            </el-dialog>
        </div>
    </div>
</template>

<script>
    import message from "../../../public/json/message.json"

    export default {
        name: "message",
        data() {
            return {
                multipleSelection: [],
                delList: [],
                // totalPage:0,
                totalPage: 30,
                option: {
                    pageIndex: '',
                    pageSize: 10,
                    currentPage: 2
                },
                content: '',
                dialogVisible: false,
                message: 'first',
                title: '',
                unread: [],
                recycle: [],
                read: [],
                id: '',
            }
        },
        methods: {
            pageChange(option) {
                this.option.currentPage = 1;

            },
            showContent(content) {
                this.dialogVisible = true;
                this.id = content.id;
                this.content = content.content;
                this.title = content.title
            },
            handleRead(id) {
                this.dialogVisible = false
            },
            deleteMessage(id) {
                this.$confirm('你确定要删除供应商信息吗？', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning',
                    center: true
                }).then(() => {
                    this.$message({
                        type: 'success',
                        message: '删除成功'
                    })
                }).catch(() => {});
            },
            handleRecycle(id) {
                alert(id)
            }
        },
        created() {
            for (var i = 0; i < message.data.length; i++) {
                if ('已读' == message.data[i].status) {
                    this.read.push(message.data[i]);
                } else if ('未读' == message.data[i].status) {
                    this.unread.push(message.data[i]);
                } else {
                    this.recycle.push(message.data[i]);
                }
            }
        }
    }
</script>

<style lang="scss" scoped>
    .page-body {
        height: 100%;
        width: 100%;
    }

    .page-footer {
        bottom: 10px;
        position: absolute !important;
        //  border: 1px red solid;
        //right: 0;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .message-title {
        cursor: pointer;
    }

    .handle-row {
        margin-top: 30px;
    }
</style>
